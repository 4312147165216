//
// (Explicit Dependencies) Import UXCore2 components.
//
import Accordion from '@ux/accordion';
import Alert from '@ux/alert';
import Button from '@ux/button';
import ButtonSet from '@ux/button-set';
import Checkbox from '@ux/form-element/checkbox';
import CheckboxGroup from '@ux/checkbox-group';
import Collapsible from '@ux/collapsible';
import Criteria from '@ux/form-element/criteria';
import Dropdown from '@ux/dropdown';
import Fieldset from '@ux/fieldset';
import Form from '@ux/form';
import FormElement from '@ux/form-element';
import FormGroup from '@ux/form-group';
import Growl from '@ux/growl';
import MessageOverlay from '@ux/message-overlay';
import Modal from '@ux/modal';
import Pagination from '@ux/pagination';
import Password from '@ux/form-element/password';
import Pivots from '@ux/pivot';
import ProgressBar from '@ux/progress-bar';
import ProgressSteps from '@ux/progress-steps';
import QuantitySelector from '@ux/form-element/quantityselector';
import Radio from '@ux/form-element/radio';
import RadioGroup from '@ux/radio-group';
import Remaining from '@ux/remaining';
import Search from '@ux/form-element/search';
import Spinner from '@ux/spinner';
import Table from '@ux/table';
import Tabs from '@ux/tabs';
import TelephoneInput from '@ux/form-element/telephone-input';
import Text from '@ux/text';
import Toggle from '@ux/toggle';
import Tooltip from '@ux/tooltip';
import request from '@godaddy/request';
import trfq from 'react-trfq';
import * as utils from '@ux/util';
import * as Validation from 'react-validation-context';

const versions = {
  // eslint-disable-next-line no-process-env
  [process.env.BUNDLE_NAME]: process.env.BUNDLE_VERSION
};

//
// In order to provide a frictionless upgrade for our teams we're automatically
// enhancing the @ux/icons class names that our partner teams are using while
// they are transitioning towards the new Icon library.
//
import '@ux/icon/enhance.js';

//
// Due to Safari lacking support for :focus-visible we need to add a temp script
// that adds similar behavior. This script adds a class on the body and using css
// variables we use :focus to do the same styles we would with :focus-visible.
// Once Safari has adequate :focus-visible support this js code will be removed
// and styles will switch from :focus to :focus-visible.
//
utils.registerKeyboardNavListener();

// Babel7 doesn't attach __esModule onto `import *`s even if they are actually es modules.
// For now, manually attach them for compatibility
[utils].forEach(function (thing) {
  Object.defineProperty(thing, '__esModule', {
    value: true
  });
});

//
// Also export Components to allow other applications depend directly on
// UXCore2. This cannot be done dynamically by destructuring the components as
// this would violate the assumption of statically defined imports/exports.
//
// Note: do NOT export as default, it will prevent `{ [Component] }` like imports.
//
export {
  Accordion,
  Alert,
  Button,
  ButtonSet,
  Checkbox,
  CheckboxGroup,
  Collapsible,
  Criteria,
  Dropdown,
  Fieldset,
  Form,
  FormElement,
  FormGroup,
  Growl,
  MessageOverlay,
  Modal,
  Pagination,
  Password,
  Pivots,
  ProgressBar,
  ProgressSteps,
  QuantitySelector,
  Radio,
  RadioGroup,
  Remaining,
  Search,
  Spinner,
  Table,
  Tabs,
  TelephoneInput,
  Text,
  Toggle,
  Tooltip,
  request,
  trfq,
  utils,
  Validation,
  versions
};
